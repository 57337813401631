import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import { CustomerConfig } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import { useFormatMoney } from '@core/money';
import { useUserStore } from '@core/user';

import type { CasinoGameBonusReminderModalEmits, CasinoGameBonusReminderModalProps } from '../types';

export interface CasinoGameBonusReminderModalComposable {
  isVisible: Ref<boolean>;
  isDontShowAgain: Ref<boolean>;
  maxBetLimitFormatted: Ref<string>;
  onChangeConsent(event: Event): void;
  onCloseReminderModal(): Promise<void>;
  onBonusLinkClick(): void;
}

export default function useCasinoGameBonusReminderModal(
  props: RequiredField<CasinoGameBonusReminderModalProps, 'hideLimitModalOnGamePage'> & {
    maxBetLimit?: CasinoGameBonusReminderModalProps['maxBetLimit'];
  },
  emits: CasinoGameBonusReminderModalEmits,
): CasinoGameBonusReminderModalComposable {
  const { setAdditionalPropsUser } = useUserStore();
  const formatMoney = useFormatMoney();
  const router = useRouter();

  const isDontShowAgain = ref(false);
  const isVisible = ref(false);

  const maxBetLimitFormatted = computed(() => formatMoney(
    props.maxBetLimit?.amount || 0,
    { currency: props.maxBetLimit?.currency },
  ));

  onBeforeMount(() => {
    isVisible.value = !props.hideLimitModalOnGamePage;
  });

  function onChangeConsent(event: Event): void {
    const { checked } = event.target as HTMLInputElement;
    isDontShowAgain.value = checked;
  }

  async function onCloseReminderModal(): Promise<void> {
    if (isDontShowAgain.value) {
      await setAdditionalPropsUser({
        value: {
          value: isDontShowAgain.value,
        },
        config: CustomerConfig.HIDE_LIMIT_MODAL_ON_GAME_PAGE,
      });
    }

    isVisible.value = false;
    emits('close');
  }

  function onBonusLinkClick(): void {
    isVisible.value = false;
    void router.push({ name: RouteName.BONUSES });
  }

  return {
    isDontShowAgain,
    isVisible,
    maxBetLimitFormatted,
    onChangeConsent,
    onCloseReminderModal,
    onBonusLinkClick,
  };
}
