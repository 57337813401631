<script setup lang="ts">
import { VSwitch } from '@components/switch';

import type {
  CasinoGameRealMoneySwitchEmits,
  CasinoGameRealMoneySwitchProps,
} from './types';

defineProps<CasinoGameRealMoneySwitchProps>();
const emit = defineEmits<CasinoGameRealMoneySwitchEmits>();
</script>

<template>
  <div v-auto-id="'CasinoGameRealMoneySwitch'"
    :class="$style['casino-game-real-money-switch']"
  >
    <VSwitch
      :class="$style['casino-game-real-money-switch__switch']"
      :label="$t('WEB2_MONEY_PLAY')"
      :checked="isChecked"
      @click.prevent="emit('click')"
    />
  </div>
</template>

<style module lang="scss">
.casino-game-real-money-switch {
  display: flex;
  align-items: center;
  height: 36px;
  padding: $casinoGameRealMoneySwitchPadding;
  background-color: $casinoGameRealMoneySwitchBackground;
  border-radius: $casinoGameRealMoneySwitchBorderRadius;

  &__switch {
    white-space: nowrap;
  }
}
</style>
