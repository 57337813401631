import type { EgsGame } from 'web/src/modules/egs/types';

export interface CasinoActiveGameEventOptions {
  game: {
    id: string;
    name: string;
    category: string;
    image: string;
    provider: {
      id: string;
      game: string;
    };
  };
}

export default function mapActiveGameToAnalytics(game: EgsGame): CasinoActiveGameEventOptions {
  const { categories, images } = game;

  return {
    game: {
      id: game.id,
      name: game.name,
      category: categories[0]?.nameEn || '',
      image: images.preview?.src || '',
      provider: {
        game: game.name,
        id: game.providerId,
      },
    },
  };
}
