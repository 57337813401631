import type { Ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import {
  computed,
  toRef,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import {
  getBaseHeaders,
  languageHeaderName,
  resolveApi2Url,
  themeHeaderName,
} from '@leon-hub/api';
import { RouteName } from '@leon-hub/routing-config-names';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { removeQueryParameters } from '@leon-hub/utils';

import { useI18nStore } from '@core/i18n';
import { useTheme } from '@core/theme';

import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';

import { newWindowExitUrl } from './constants';

export interface CasinoGameExitUrls {
  backToLobbyLocation: Ref<RouteLocationNamedRaw>;
  bankingLocation: RouteLocationNamedRaw;
  closeUrl: Ref<string>;
  getBankingUrl(): string;
  getExitUrl(): string;
  getExitHref(): string;
  getBankingHref(): string;
  getNewWindowUrl(gameId: string, isDemo: boolean): string;
}

export default function useCasinoGameExitUrls(): CasinoGameExitUrls {
  const router = useRouter();
  const { theme } = useTheme();

  const gamesStore = useCasinoGameStore();
  const backToLobbyLocation = toRef(gamesStore, 'activeGameBackToLobbyLocation');
  const closeUrl = computed(() => router.resolve(backToLobbyLocation.value).fullPath);
  const bankingLocation: RouteLocationNamedRaw = {
    name: RouteName.DEPOSITS,
  };

  function getExitPath(url: string): string {
    let baseUrl = getLocationOrigin();
    const urlParameter = removeQueryParameters(url);

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      baseUrl = `${window.location.origin}/egs-exit.html?url=${urlParameter}`;
    } else {
      baseUrl += `/egs-exit.html?url=${urlParameter}`;
    }

    return baseUrl;
  }

  function getExitHref(): string {
    return router.getPrevHistoryUrl() || closeUrl.value;
  }

  function getBankingHref(): string {
    return router.resolve(bankingLocation).fullPath;
  }

  function getBankingUrl(): string {
    return getExitPath(getBankingHref());
  }

  function getExitUrl(): string {
    return getExitPath(getExitHref());
  }

  function getNewWindowUrl(gameId: string, isDemo: boolean): string {
    const nextHeaders = {
      [languageHeaderName]: useI18nStore().locale,
      [themeHeaderName]: theme.value.toLowerCase(),
    };
    const headers = getBaseHeaders(nextHeaders);

    const parameters = Object.keys(headers)
      .map((key) => `${key.toLowerCase()}=${headers[key]}`)
      .join('&');
    const server = resolveApi2Url();
    return `${server}/externalgames/start/${gameId}`
      + `?isDemo=${isDemo}&${parameters}&exitUrl=${newWindowExitUrl}&bankingUrl=${getBankingUrl()}`;
  }

  return {
    backToLobbyLocation,
    bankingLocation,
    closeUrl,
    getBankingUrl,
    getExitUrl,
    getExitHref,
    getBankingHref,
    getNewWindowUrl,
  };
}
