import type { Ref } from 'vue';
import {
  onBeforeUnmount,
  onMounted,
  toRef,
} from 'vue';

import { Timer } from '@leon-hub/utils';

import { useSiteConfigStore } from '@core/site-config';

import { useCashbackStore } from 'web/src/modules/promotions/store';

export interface CasinoGameCashbackComposable {
  isCashbackDisplay: Ref<boolean>;
}

export default function useCasinoGameCashback(): CasinoGameCashbackComposable {
  const cashbackStore = useCashbackStore();
  const isCashbackDisplay = toRef(cashbackStore, 'isCashbackDisplay');
  const timestampStartDate = toRef(cashbackStore, 'timestampStartDate');
  const timestampEndDate = toRef(cashbackStore, 'timestampEndDate');

  const scgStore = useSiteConfigStore();
  const isWeb2BonusCashbackWidgetEnabled = toRef(scgStore, 'isWeb2BonusCashbackWidgetEnabled');

  let beforeStartCashbackTimeoutId = 0;

  function stopCashbackTimer(): void {
    if (beforeStartCashbackTimeoutId) {
      Timer.clearTimeout(beforeStartCashbackTimeoutId);
      beforeStartCashbackTimeoutId = 0;
    }
  }

  async function setBeforeStartCashbackTimer(): Promise<void> {
    const currentTimestamp = Date.now();

    await cashbackStore.getCashback();

    stopCashbackTimer();

    if (currentTimestamp < timestampStartDate.value) {
      const delay = timestampStartDate.value - currentTimestamp;

      beforeStartCashbackTimeoutId = Timer.setTimeout(() => {
        cashbackStore.setCashbackActive(true);
      }, delay);
    } else if (currentTimestamp < timestampEndDate.value) {
      cashbackStore.setCashbackActive(true);
    }
  }

  onMounted(() => {
    if (isWeb2BonusCashbackWidgetEnabled.value) {
      void setBeforeStartCashbackTimer();
    }
  });

  onBeforeUnmount(stopCashbackTimer);

  return {
    isCashbackDisplay,
  };
}
