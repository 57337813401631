<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';

import { ButtonKind, SButton, VButton } from '@components/buttons';
import { VCheckbox } from '@components/checkbox';
import { ModalWidth } from '@components/dialogs';

import { DefaultModal } from 'web/src/components/Modal/DefaultModal';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import CasinoGamePromotionsModalTimer
  from 'web/src/modules/casino/submodules/game/views/CasinoGamePromotionsModal/components/CasinoGamePromotionsModalTimer/CasinoGamePromotionsModalTimer.vue';

import type { CasinoGamePromotionsModalProps } from './types';
import { useCasinoGamePromotionsModal } from './composables';

const props = withDefaults(defineProps<CasinoGamePromotionsModalProps>(), {
});

const {
  selector,
  iconClose,
  isEnded,
  checkboxIsChecked,
  prizePool,
  casinoModalData,
  isVisible,
  onCheckboxChange,
  onPlay,
  onClose,
  onHeaderClose,
  onReadMore,
  promotionEnd,
} = useCasinoGamePromotionsModal(props);
</script>

<template>
  <ModalPortal v-auto-id="'CasinoGamePromotionsModal'"
    v-if="casinoModalData?.jackpot && isVisible"
    :selector="selector"
  >
    <DefaultModal
      is-top-bar-hidden
      :is-alert="!!'1'"
      :is-close-button-allowed="false"
      :width="ModalWidth.SMALL_MINUS"
      :class="$style['promotion-modal']"
      @close="onClose"
    >
      <template #header>
        <div
          :class="{
            [$style['promotion-modal__header']]: true,
            [$style['promotion-modal__header--ended']]: isEnded,
          }"
          :style="{
            backgroundImage: `url(${casinoModalData?.promotions[0]?.shortBannerImage?.src || casinoModalData?.promotions[0].bannerUrl})`,
            backgroundColor: casinoModalData?.promotions[0].bgColor || undefined,
          }"
        >
          <CasinoGamePromotionsModalTimer
            v-if="!isEnded"
            :timestamp="casinoModalData?.promotions[0].endDateNumber || 0"
            @end="promotionEnd"
          />
        </div>

        <SButton
          v-if="null"
          :class="$style['promotion-modal__button-close']"
          :icon-name="iconClose"
          :icon-size="IconSize.SIZE_24"
          kind="secondary"
          @click="onHeaderClose"
        />
        <VButton
          v-else-if="!null"
          :class="$style['promotion-modal__button-close']"
          :icon-name="iconClose"
          :icon-size="IconSize.SIZE_24"
          :kind="ButtonKind.TRANSPARENT_DARK_VERSION"
          @click="onHeaderClose"
        />
      </template>
      <template #content>
        <div :class="$style['promotion-modal__content']">
          <div :class="$style['promotion-modal__title']">
            {{ casinoModalData?.promotions[0].name }}
          </div>
          <div :class="$style['promotion-modal__prize']">
            <span
              v-if="isEnded"
              :class="$style['promotion-modal__finished']"
            >
              {{ $t('WEB2_GAME_PROMOTIONS_MODAL_ENDED') }}
            </span>
            <template v-else-if="casinoModalData?.jackpot?.amount.value">
              <div :class="$style['promotion-modal__prize-title']">
                {{ $t('WEB2_GAME_PROMOTIONS_MODAL_PRIZE_POOL') }}
              </div>
              <div
                :class="$style['promotion-modal__prize-count']"
              >
                {{ prizePool }}
              </div>
            </template>
          </div>
        </div>

        <template v-if="!isEnded">
          <div
            v-if="null"
            :class="$style['promotion-modal__buttons']"
          >
            <SButton
              full-width
              :label="$t('WEB2_GAME_PROMOTIONS_MODAL_PLAY_NOW')"
              kind="primary"
              @click="onPlay"
            />
            <SButton
              full-width
              :label="$t('WEB2_GAME_PROMOTIONS_MODAL_READ_MORE')"
              kind="tertiary-secondary"
              @click="onReadMore"
            />
          </div>
          <div
            v-else
            :class="$style['promotion-modal__buttons']"
          >
            <VButton
              full-width
              :label="$t('WEB2_GAME_PROMOTIONS_MODAL_PLAY_NOW')"
              :kind="ButtonKind.PRIMARY"
              @click="onPlay"
            />
            <VButton
              full-width
              :label="$t('WEB2_GAME_PROMOTIONS_MODAL_READ_MORE')"
              :kind="ButtonKind.SECONDARY"
              @click="onReadMore"
            />
          </div>

          <VCheckbox
            :checked="checkboxIsChecked"
            :class="$style['promotion-modal__checkbox']"
            :label="$t('WEB2_GAME_PROMOTIONS_MODAL_HIDE')"
            @change="onCheckboxChange"
          />
        </template>
      </template>
    </DefaultModal>
  </ModalPortal>
</template>

<style lang="scss" module>
.promotion-modal {
  &__header {
    @include casino-game-promotion-modal-header;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
    }

    &--ended {
      filter: grayscale(100%);
    }
  }

  &__finished {
    @include casino-game-promotion-modal-finished;
  }

  &__content {
    @include casino-game-promotion-modal-content;

    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-top: 16px;

    &:before {
      @include casino-game-promotion-modal-content-before;

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      content: '';
    }
  }

  &__title {
    @include casino-game-promotion-modal-title;

    z-index: 1;
  }

  &__prize {
    z-index: 1;
    display: inline-flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;

    &-title {
      @include casino-game-promotion-modal-prize-title;
    }

    &-count {
      @include casino-game-promotion-modal-prize-count;
    }
  }

  &__buttons {
    @include casino-game-promotion-modal-buttons;

    display: flex;
    flex-direction: column;
  }

  &__button {
    &-close {
      @include casino-game-promotion-modal-close-button;

      position: absolute;
    }
  }

  &__checkbox {
    position: relative;
    z-index: 1;
    display: inline-flex;
  }
}
</style>
