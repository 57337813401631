import type {
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { ApiError } from '@leon-hub/api';
import { stopVpn } from '@leon-hub/cordova';
import {
  assert,
  isString,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useIsLoggedIn } from '@core/auth';
import { AbstractPrefetch } from '@core/router';

import { useCasinoGameAnalytics } from 'web/src/modules/casino/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import {
  EgsRouterParametersHandler,
  isDemoMethod,
} from 'web/src/modules/egs/submodules/navigation/utils';

export default class CasinoGamePagePrefetch extends AbstractPrefetch {
  async prefetch(
    _: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const gamesStore = useCasinoGameStore();
    const activeGame = toRef(gamesStore, 'activeGame');

    const {
      groupUrl,
      gameUrl,
    } = to.params;

    assert(isString(groupUrl));
    assert(isString(gameUrl));

    const gameStartMethod = EgsRouterParametersHandler.getSelectedGameMethod();
    EgsRouterParametersHandler.setSelectedGameMethod(undefined);

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await stopVpn();
    }

    try {
      await gamesStore.setActiveUrl(groupUrl, gameUrl);
    } catch (error) {
      if (!(error instanceof ApiError) || error.code.toString() !== 'EGS_GAME_UNAVAILABLE') {
        throw error;
      }
    }

    const { isLoggedIn } = useIsLoggedIn();

    if (!activeGame.value) {
      return {
        name: RouteName.ERROR_NOT_FOUND_404,
      };
    }

    if (from.fullPath !== to.fullPath) {
      gamesStore.setDemoStartMode(isDemoMethod(activeGame.value, isLoggedIn.value, gameStartMethod));
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      useCasinoGameAnalytics().sendOpenGameMetrika(gamesStore.isDemo);
    }
  }
}
