<script lang="ts" setup>
import type { Ref } from 'vue';
import { onMounted, onUnmounted, ref } from 'vue';

import { Timer } from '@leon-hub/utils';

import type {
  CasinoGamePromotionsModalTime,
  CasinoGamePromotionsModalTimerEmits,
  CasinoGamePromotionsModalTimerProps,
} from './types';

const props = withDefaults(defineProps<CasinoGamePromotionsModalTimerProps>(), {});

const emit = defineEmits<CasinoGamePromotionsModalTimerEmits>();

const time: Ref<CasinoGamePromotionsModalTime> = ref({
  days: '',
  hours: '',
  minutes: '',
  seconds: '',
});

let intervalId: number;

onMounted(() => {
  intervalId = Timer.setInterval(() => {
    const now = Date.now();
    const diff = props.timestamp - now;

    if (diff <= 0) {
      clearInterval(intervalId);
      time.value = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
      emit('end');
      return;
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    time.value = {
      days: days.toString().padStart(2, '0'),
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  }, 1000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <div v-auto-id="'CasinoGamePromotionsModalTimer'" :class="$style['casino-game-promotion-modal-timer']">
    <span
      :class="$style['casino-game-promotion-modal-timer-title']"
    >
      {{ $t('WEB2_GAME_PROMOTIONS_MODAL_TOURNAMENT') }}
    </span>
    <span
      :class="$style['casino-game-promotion-modal-timer-numbers']"
    >
      <span :class="$style['casino-game-promotion-modal-timer-number']">
        <span :class="$style['casino-game-promotion-modal-timer-count']">{{ time.days }}</span>
        <span :class="$style['casino-game-promotion-modal-timer-description']">
          {{ $t('WEB2_GAME_PROMOTIONS_MODAL_TIMER_DAYS') }}
        </span>
      </span>

      <span
        :class="$style['casino-game-promotion-modal-timer-colon']"
      >
        :
      </span>

      <span :class="$style['casino-game-promotion-modal-timer-number']">
        <span :class="$style['casino-game-promotion-modal-timer-count']">{{ time.hours }}</span>
        <span :class="$style['casino-game-promotion-modal-timer-description']">
          {{ $t('WEB2_GAME_PROMOTIONS_MODAL_TIMER_HOURS') }}
        </span>
      </span>

      <span
        :class="$style['casino-game-promotion-modal-timer-colon']"
      >
        :
      </span>

      <span :class="$style['casino-game-promotion-modal-timer-number']">
        <span :class="$style['casino-game-promotion-modal-timer-count']">{{ time.minutes }}</span>
        <span :class="$style['casino-game-promotion-modal-timer-description']">
          {{ $t('WEB2_GAME_PROMOTIONS_MODAL_TIMER_MINUTES') }}
        </span>
      </span>

      <span
        :class="$style['casino-game-promotion-modal-timer-colon']"
      >
        :
      </span>

      <span :class="$style['casino-game-promotion-modal-timer-number']">
        <span :class="$style['casino-game-promotion-modal-timer-count']">{{ time.seconds }}</span>
        <span :class="$style['casino-game-promotion-modal-timer-description']">
          {{ $t('WEB2_GAME_PROMOTIONS_MODAL_TIMER_SECONDS') }}
        </span>
      </span>
    </span>
  </div>
</template>

<style lang="scss" module>
.casino-game-promotion-modal-timer {
  @include casino-game-promotion-modal-timer;

  position: relative;

  &-numbers {
    @include casino-game-promotion-modal-timer-numbers;
  }

  &-number {
    @include casino-game-promotion-modal-timer-number;
  }

  &-title {
    @include casino-game-promotion-modal-timer-title;
  }

  &-count {
    @include casino-game-promotion-modal-timer-count;
  }

  &-description {
    @include casino-game-promotion-modal-timer-description;
  }

  &-colon {
    @include casino-game-promotion-modal-timer-colon;
  }
}
</style>
