import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import {
  captchaTypeInvisibleRecaptcha,
} from '@leon-hub/api-sdk';
import {
  createUrlSlug,
  getRandomString,
} from '@leon-hub/utils';

import { useSiteConfigStore } from '@core/site-config';

import type { EgsGame } from 'web/src/modules/egs/types';
import { prerenderEgsGameIframeWidgetName } from 'web/src/modules/framed-app/constants';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

export function usePrerenderWidgetUrl(
  activeGame: Ref<EgsGame | undefined>,
): Ref<string | undefined> {
  if (!process.env.VUE_APP_PRERENDER) {
    return ref(undefined);
  }

  const siteConfigStore = useSiteConfigStore();
  const captchaBlock = toRef(() => siteConfigStore.captcha);
  const captchaKey = computed(() => captchaBlock.value?.recaptcha?.siteKeys?.find((key) => key.captchaType === captchaTypeInvisibleRecaptcha)?.key);
  const prerenderWidgetQueryParameters = computed<Record<string, string>>(() => ({
    game: createUrlSlug(activeGame?.value?.name ?? ''),
    provider: createUrlSlug(activeGame?.value?.group?.name ?? ''),
    sid: captchaKey.value ? getRandomString() + btoa(`${getRandomString()}${captchaKey.value}`) : '',
  }));
  return useFramedWidgetUrl(
    prerenderEgsGameIframeWidgetName,
    undefined,
    prerenderWidgetQueryParameters,
  );
}
