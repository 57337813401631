import type {
  RouteLocationNormalized,
} from 'vue-router';

import type { SyncAbstractPrefetchResult } from '@core/router';

export default function casinoExitRoutePageBeforeEnter(
  to: RouteLocationNormalized,
): SyncAbstractPrefetchResult {
  try {
    const newUrl = to.query.url as string || '/';

    if (process.env.VUE_APP_RENDERING_CSR && (!window.top || window.top === window)) {
      return ({
        path: newUrl,
        replace: true,
      });
    }
  } catch {
    //
  }
}
