<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';

import { BadgeKind, VBadge } from '@components/badge';

import CasinoGameIframeError from 'web/src/modules/casino/components/CasinoGameIframeError/CasinoGameIframeError.vue';
import CasinoGameLoader from 'web/src/modules/casino/components/CasinoGameLoader/CasinoGameLoader.vue';
import CasinoControlBar from 'web/src/modules/casino/submodules/game/components/CasinoGameControlBar/CasinoGameControlBar.vue';
import CasinoGameDemoBar from 'web/src/modules/casino/submodules/game/components/CasinoGameDemoBar/CasinoGameDemoBar.vue';
import CasinoGameDescription from 'web/src/modules/casino/submodules/game/components/CasinoGameDescription/CasinoGameDescription.vue';
import { PanelLayout } from 'web/src/modules/casino/submodules/game/enums';
import CasinoGameCashbackContainer from 'web/src/modules/casino/submodules/game/views/CasinoGameCashbackContainer/CasinoGameCashbackContainer.vue';
import CasinoGameLeaderBoard from 'web/src/modules/casino/submodules/game/views/CasinoGameLeaderBoard/CasinoGameLeaderBoard.vue';
import CasinoGamePromotionsModal
  from 'web/src/modules/casino/submodules/game/views/CasinoGamePromotionsModal/CasinoGamePromotionsModal.vue';
import SCasinoGameSideBarContainer from 'web/src/modules/casino/submodules/game/views/CasinoGameSideBarContainer/components/SCasinoGameSideBarContainer.vue';
import CasinoGameVpnLoader from 'web/src/modules/casino/submodules/game/views/CasinoGameVpnLoader/CasinoGameVpnLoader.vue';
import EgsGameIframe from 'web/src/modules/egs/components/EgsGameIframe/EgsGameIframe.vue';

import type {
  CasinoGameStartEmits,
  CasinoGameStartProps,
} from './types';
import CasinoGameBonusContainer from '../CasinoGameBonusContainer/CasinoGameBonusContainer.vue';
import CasinoGameListContainer from '../CasinoGameListContainer/CasinoGameListContainer.vue';
import CasinoGameSideBarContainer from '../CasinoGameSideBarContainer/CasinoGameSideBarContainer.vue';
import { useCasinoGameStart } from './composables';

const props = withDefaults(defineProps<CasinoGameStartProps>(), {
  userLogin: '',
  gameUrl: '',
});
const emit = defineEmits<CasinoGameStartEmits>();

const {
  wrapperStyles,
  isSideBarOverGame,
  isVpnGame,
  hasGameDescription,
  isLoggedIn,
  isEgsTournamentsPromoModalEnabled,
  isLoyaltyProgramFeatureEnabled,
  onLoaderMounted,
  onIFrameLoaded,
} = useCasinoGameStart(props);

function onFrameLoaded(): void {
  emit('set-frame-loaded');
  onIFrameLoaded();
}

// TODO: get from EGSGame when provided
const iframeAllow = computed<string>(() => (
  props.activeGame.group.id === 'absolute_live_gaming'
    ? 'fullscreen *, autoplay *'
    : 'autoplay; fullscreen; camera; microphone'
));

const isFrameBlocked = ref(false);
function onSidebarMouseDown(): void {
  const onMouseUp = () => {
    isFrameBlocked.value = false;
    document.removeEventListener('mouseup', onMouseUp);
  };
  isFrameBlocked.value = true;
  document.addEventListener('mouseup', onMouseUp);
}
</script>

<template>
  <div v-auto-id="'CasinoGameStart'"
    :class="{
      [$style['casino-game-page']]: true,
      [$style['casino-game-page--demo-bar-visible']]: null && isDemo,
    }"
  >
    <CasinoGamePromotionsModal
      v-if="isLoggedIn && isEgsTournamentsPromoModalEnabled"
      :active-game="activeGame"
    />
    <div :class="$style['casino-game-page__window-wrapper']">
      <div
        :class="$style['casino-game-page__window']"
      >
        <div
          :class="{
            [$style['casino-game-page__window-inner']]: true,
          }"
        >
          <CasinoControlBar
            v-if="'1'"
            :title="activeGame.name"
            :is-favorite="activeGame.isFavorite"
            :is-checked-switch="!isDemo"
            :is-demo-enabled="activeGame.hasDemo"
            :is-chat-enabled="isChatEnabled"
            :close-href="closeHref"
            :is-loyalty-switcher-enabled="isLoyaltySwitcherEnabled"
            :is-over-game="isSideBarOverGame"
            :is-side-bar-opened="isSideBarOpened"
            @click-chat="emit('click-chat')"
            @close="emit('close')"
            @click-switch="emit('click-switch', $event)"
            @favorite="emit('favorite')"
            @fullscreen="emit('fullscreen')"
            @side-bar-toggle="emit('side-bar-toggle')"
            @restart-game="emit('restart-game')"
          />
          <div
            :class="{
              [$style['casino-game-page__game']]: true,
              [$style['casino-game-page__game--error']]: !!'1' && !!error,
            }"
          >
            <div :class="$style['casino-game-page__game-badges']">
              <VBadge
                v-if="activeGame.ntBadge"
                :kind="BadgeKind.SQUARE_ERROR"
                :label="activeGame.ntBadge"
                :class="$style['casino-game-page__nt-badge']"
              />
            </div>
            <div
              :class="$style['casino-game-page__iframe-wrapper']"
            >
              <template v-if="!isFrameLoaded">
                <CasinoGameVpnLoader
                  v-if="null && isVpnGame"
                  :class="$style['casino-game-page__loader']"
                  @hook:mounted="onLoaderMounted"
                />
                <CasinoGameLoader
                  v-else
                  :class="$style['casino-game-page__loader']"
                />
              </template>
              <template v-if="'1' && error">
                <CasinoGameIframeError
                  v-bind="error"
                  :class="$style['casino-game-page__iframe-error']"
                  @click="emit('error-click', $event)"
                />
              </template>
              <template v-if="!isLoading && !error">
                <EgsGameIframe
                  :game-url="gameUrl"
                  :start-code-type="startCodeType"
                  :allow="iframeAllow"
                  allowfullscreen
                  :timeout="!isVpnGame ? timeout : undefined"
                  :class="$style['casino-game-page__iframe']"
                  @load="onFrameLoaded"
                  @error="emit('on-frame-error', $event)"
                />
                <div
                  v-if="isFrameBlocked"
                  :class="$style['casino-game-page__block']"
                />
                <template v-if="null && isDemo">
                  <CasinoGameDemoBar
                    :is-logged-in="isLoggedIn"
                    @go-to-real-game="emit('go-to-real-game')"
                    @go-to-login="emit('go-to-login')"
                  />
                </template>
              </template>
            </div>
            <div
              v-if="!isLoading && !error"
              :class="$style['casino-game-page__bonuses-container']"
            >
              <div :class="$style['casino-game-page__bonuses-item']">
                <CasinoGameBonusContainer
                  v-if="'1' && !null"
                  :layout="PanelLayout.SLOTS_BAR"
                  @withdraw-bonus="emit('restart-game')"
                />
              </div>
              <div
                v-if="'1' && !null && isCashbackDisplay"
                :class="$style['casino-game-page__bonuses-item']"
              >
                <CasinoGameCashbackContainer />
              </div>
            </div>
          </div>
        </div>
        <SCasinoGameSideBarContainer
          v-if="null && '1'"
          :game="activeGame"
          :class="$style['casino-game-page__sidebar']"
          @mousedown="onSidebarMouseDown"
        />
        <CasinoGameSideBarContainer
          v-if="!null && '1' && isLoyaltySwitcherEnabled"
          :is-over-game="isSideBarOverGame"
          :is-hide-my-bonuses="!!null || isCashbackDisplay || !isLoyaltyProgramFeatureEnabled"
        />
      </div>
      <Teleport
        v-if="'1' && !null"
        defer
        to="#js__content-scroll"
      >
        <div>
          <div
            :class="$style['casino-game-page__window-wrapper-image']"
            :style="wrapperStyles"
          />
          <div
            :class="$style['casino-game-page__gradient']"
          />
        </div>
      </Teleport>
    </div>
    <div
      v-if="'1'"
      :class="$style['casino-game-page__footer']"
    >
      <CasinoGameListContainer />
      <CasinoGameLeaderBoard />
      <CasinoGameDescription
        v-if="!null && hasGameDescription"
        :game="activeGame"
        is-open
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-game-page {
    @if $isDesktop {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: $layoutDesktopMaxWidth;
      height: 100%;
      padding: $casinoGameStartPaddingDesktop;
      margin: 0 auto;
    }

    &__window {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: $casinoGameStartGapBetweenContentAndSidebar;

      @if $isDesktop {
        @include z-index(base);

        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;
        margin: $casinoGameStartWindowMargin;
      }
    }

    &__window-wrapper {
      @if $isDesktop {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }

    &__window-wrapper-image {
      @if $isDesktop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 660px;
        background-repeat: no-repeat;
        background-position-y: top;
        background-size: cover;
        filter: blur(10px);
      }
    }

    &__window-inner {
      @if $isDesktop {
        width: 100%;
        max-width: $casinoGameStartIframeWidthNoSidebar;
      }
    }

    &__game {
      position: relative;

      @if $isDesktop {
        width: 100%;
        overflow: hidden;
        border-radius: $casinoGameStartIframeBorderRadius;
        box-shadow: $casinoGameStartIframeShadow;
      }
    }

    &__iframe-wrapper {
      @if $isDesktop {
        height: $casinoGameStartIframeHeight;
        min-height: $casinoGameStartIframeHeight;
      }
    }

    &__block {
      @if $isDesktop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &__loader,
    &__iframe-error {
      position: if($isDesktop, absolute, fixed);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
    }

    &__bonuses-container {
      background-color: var(--EgsOverlayBarColor);
      border-radius: 0 0 5px 5px;
    }

    &__bonuses-item {
      & + & {
        position: relative;

        &:before {
          position: absolute;
          top: 0;
          right: 16px;
          left: 16px;
          height: 1px;
          content: '';
          background-color: var(--Layer1);
        }
      }
    }

    &__game-badges {
      @include z-index('absolute-item');

      position: absolute;
      top: 5px;
      right: 5px;
      pointer-events: none;
    }

    &__iframe {
      @if not $isDesktop {
        position: fixed;
        top: var(--StatusbarHeight);
        bottom: 0;
        height: auto;
      }
    }

    &--demo-bar-visible {
      @if not $isDesktop {
        .casino-game-page__iframe {
          padding-bottom: $casinoGameDemoBarHeight;
        }
      }
    }

    &__footer {
      @if $isDesktop {
        width: 100%;
        max-width: $layoutDesktopMaxWidth;
        padding: $casinoGameFooterPadding;
        margin-top: $casinoGameStartFooterMarginTopSmall;

        @include screen-desktop-large-min {
          margin-top: $casinoGameStartFooterMarginTopBig;
        }
      }
    }

    &__gradient {
      @if $isDesktop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 700px;
        background: linear-gradient(180deg, transparent 0%, var(--Layer0) 100%);
      }
    }

    &__sidebar {
      height: $casinoGameSidebarHeight;
    }
  }
}

@include for-fullscreen {
  .casino-game-page {
    &__window {
      @include z-index(image-preview);

      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: none;
      padding: 0;
      margin: 0;
    }

    &__iframe {
      height: 100%;
    }

    &__iframe-wrapper {
      height: 100%;
    }

    &__game {
      height: calc(100% - 44px);
      border-radius: 0;
    }

    &__window-inner {
      max-width: none;
    }

    &__sidebar {
      display: none;
    }
  }
}
</style>
