<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import { useWindowResize } from '@leon-hub/browser-composables';
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import {
  ButtonKind,
  VButton,
} from '@components/buttons';

import BonusPointsContainer from 'web/src/modules/bonuses/components/BonusPointsContainer/BonusPointsContainer.vue';
import CasinoGameButton from 'web/src/modules/casino/submodules/game/components/CasinoGameButton/CasinoGameButton.vue';
import CasinoGameRealMoneySwitch from 'web/src/modules/casino/submodules/game/components/CasinoGameRealMoneySwitch/CasinoGameRealMoneySwitch.vue';
import CasinoGameSideBarSwitcher
  from 'web/src/modules/casino/submodules/game/components/CasinoGameSideBarSwitcher/CasinoGameSideBarSwitcher.vue';
import CasinoGameTime from 'web/src/modules/casino/submodules/game/views/CasinoGameTime/CasinoGameTime.vue';
import EgsFavorite from 'web/src/modules/egs/components/EgsFavorite/EgsFavorite.vue';

import type { CasinoGameControlBarEmits, CasinoGameControlBarProps } from './types';

const props = withDefaults(defineProps<CasinoGameControlBarProps>(), {
  title: '',
});
const emit = defineEmits<CasinoGameControlBarEmits>();

const container = ref<HTMLElement>();
const isShort = ref(false);

function setBonusPointsWidth(): void {
  const containerWidth = container.value?.offsetWidth || 0;
  const breakPointWidth = props.isDemoEnabled ? 900 : 800;

  isShort.value = containerWidth < breakPointWidth;
}

onMounted(setBonusPointsWidth);
useWindowResize(setBonusPointsWidth);

function onClickSwitch(): void {
  emit('click-switch', !props.isCheckedSwitch);
}
</script>

<template>
  <div v-auto-id="'CasinoGameControlBar'"
    ref="container"
    :class="{
      [$style['casino-control-bar']]: true,
      [$style['casino-control-bar--sidebar-opened']]: isSideBarOpened && !isOverGame,
    }"
  >
    <div
      v-data-test="{ el: 'casino-control-bar__title' }"
      :class="$style['casino-control-bar__title']"
    >
      <CasinoGameTime
        v-if="!null"
      />
      {{ title }}
    </div>
    <div>
      <BonusPointsContainer
        v-if="!null"
        is-small
        :is-short="isShort"
      />
    </div>
    <div :class="$style['casino-control-bar__controls']">
      <CasinoGameTime
        v-if="null"
      />
      <CasinoGameRealMoneySwitch
        v-if="isDemoEnabled"
        :class="$style['casino-control-bar__switch']"
        :is-checked="isCheckedSwitch"
        @click="onClickSwitch"
      />
      <template v-if="null">
        <CasinoGameButton
          :icon-inactive="IconName.SLOTT_HEART_OUTLINE"
          :icon-active="IconName.SLOTT_HEART"
          :is-active="isFavorite"
          @click="emit('favorite')"
        />
        <CasinoGameButton
          :icon-inactive="IconName.SLOTT_EXPAND"
          @click="emit('fullscreen')"
        />
        <CasinoGameButton
          :icon-inactive="IconName.SLOTT_REFRESH"
          @click="emit('restart-game')"
        />
        <CasinoGameButton
          :icon-inactive="IconName.SLOTT_HOME1"
          @click="emit('close')"
        />
      </template>
      <template v-else>
        <EgsFavorite
          :is-favorite="isFavorite"
          @click="emit('favorite')"
        />
        <VButton
          v-if="isChatEnabled"
          :icon-name="IconName.CHAT"
          :kind="ButtonKind.TRANSPARENT"
          :icon-size="IconSize.SIZE_24"
          @click.prevent="emit('click-chat')"
        />
        <VButton
          :icon-name="IconName.FULLSCREEN"
          :kind="ButtonKind.TRANSPARENT"
          :icon-size="IconSize.SIZE_24"
          @click.prevent="emit('fullscreen')"
        />
        <VButton
          :icon-name="IconName.CROSS"
          :kind="ButtonKind.TRANSPARENT"
          :icon-size="IconSize.SIZE_24"
          :tag="Tag.A"
          :href="closeHref"
          @click.prevent="emit('close')"
        />
        <CasinoGameSideBarSwitcher
          v-if="isLoyaltySwitcherEnabled"
          :class="$style['casino-control-bar__sidebar-switcher']"
          :is-side-bar-opened="isSideBarOpened"
          @click="emit('side-bar-toggle')"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-control-bar {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-column-gap: 16px;
  align-items: center;
  width: 100%;
  min-width: 750px;
  height: $casinoGameControlBarHeight;
  padding: $casinoGameControlBarPadding;
  color: var(--TextDefault);
  background-color: $casinoGameControlBarBackgroundColor;
  border-radius: $casinoGameControlBarBorderRadius;

  // for tablet Galaxy Tab S6
  @media (min-width: 700px) and (max-width: 720px) {
    min-width: 700px;
    max-width: 700px;
  }

  &__title {
    @include casinoGameControlBarTitleFont;

    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__controls {
    display: flex;
    gap: $casinoGameControlBarGap;
    align-items: center;
    justify-content: flex-end;
  }

  &__switch {
    margin-right: $casinoGameControlBarRealMarginRight;
  }

  &__sidebar-switcher {
    margin: 0 16px;
  }

  &--sidebar-opened {
    border-top-right-radius: 0;
  }
}

@include for-fullscreen {
  .casino-control-bar {
    border-radius: 0;
    backdrop-filter: blur(80px);

    &__sidebar-switcher {
      display: none;
    }
  }
}
</style>
