import type { Ref } from 'vue';
import {
  computed,
  onMounted,
  ref,
  toRef,
} from 'vue';

import { useWindowResize } from '@leon-hub/browser-composables';

import { useIsLoggedIn } from '@core/auth';
import { useSiteConfigStore } from '@core/site-config';

import { isGameDescriptionAvailable } from 'web/src/modules/casino/submodules/game/utils';
import prepareUrl from 'web/src/utils/prepareUrl';

import type { CasinoGameStartProps } from '../types';
import useCasinoGameStartCordova from './useCasinoGameStartCordova';

export interface CasinoGameStartComposable {
  wrapperStyles: Ref<Record<string, string>>;
  isSideBarOverGame: Ref<boolean>;
  isVpnGame: Ref<boolean>;
  hasGameDescription: Ref<boolean>;
  isEgsTournamentsPromoModalEnabled: Ref<boolean>;
  isLoyaltyProgramFeatureEnabled: Ref<boolean>;
  isLoggedIn: Ref<boolean>;
  onLoaderMounted(): void;
  onIFrameLoaded(): void;
}

const minWidth = 790;
const sideBarWidth = 280;

export default function useCasinoGameStart(
  props: RequiredField<CasinoGameStartProps, 'userLogin' | 'isFrameLoaded'>,
): CasinoGameStartComposable {
  const siteConfigStore = useSiteConfigStore();
  const { isLoggedIn } = useIsLoggedIn();
  const isEgsTournamentsPromoModalEnabled = toRef(() => siteConfigStore.isEgsTournamentsPromoModalEnabled);
  const isLoyaltyProgramFeatureEnabled = toRef(() => siteConfigStore.isLoyaltyEnabledMain);

  const wrapperStyles = computed<Record<string, string>>(() => {
    const styles: Record<string, string> = {};
    if (process.env.VUE_APP_LAYOUT_DESKTOP && !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && props.activeGame.images.background?.src) {
      styles.backgroundImage = `url(${prepareUrl(props.activeGame.images.background.src)})`;
    }

    return styles;
  });
  const isVpnGame = computed(() => props.activeGame.isVpnAllowed);
  const hasGameDescription = computed(() => isGameDescriptionAvailable(props.activeGame));

  const isSideBarOverGame = ref(false);

  function calculateSideBarOverGame(): void {
    isSideBarOverGame.value = window.innerWidth < minWidth + sideBarWidth;
  }

  onMounted(calculateSideBarOverGame);
  useWindowResize(calculateSideBarOverGame);

  let onLoaderMounted = () => {};
  let onIFrameLoaded = () => {};

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const cordovaMethods = useCasinoGameStartCordova(props, isVpnGame);
    onLoaderMounted = cordovaMethods.onLoaderMounted;
    onIFrameLoaded = cordovaMethods.onIFrameLoaded;
  }

  return {
    wrapperStyles,
    isSideBarOverGame,
    isVpnGame,
    hasGameDescription,
    isLoggedIn,
    isEgsTournamentsPromoModalEnabled,
    isLoyaltyProgramFeatureEnabled,
    onLoaderMounted,
    onIFrameLoaded,
  };
}
