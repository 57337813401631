<script lang="ts" setup>
import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';
import { VCheckbox } from '@components/checkbox';
import { ModalWidth } from '@components/dialogs';

import { LazyDefaultModal as DefaultModal } from 'web/src/components/Modal/DefaultModal';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { ModalSelector } from 'web/src/modules/core/enums';

import type { CasinoGameBonusReminderModalEmits, CasinoGameBonusReminderModalProps } from './types';
import { useCasinoGameBonusReminderModal } from './composables';

const props = withDefaults(defineProps<CasinoGameBonusReminderModalProps>(), {});
const emits = defineEmits<CasinoGameBonusReminderModalEmits>();
const {
  isVisible,
  isDontShowAgain,
  maxBetLimitFormatted,
  onCloseReminderModal,
  onChangeConsent,
  onBonusLinkClick,
} = useCasinoGameBonusReminderModal(props, emits);
</script>

<template>
  <ModalPortal v-auto-id="'CasinoGameBonusReminderModal'"
    v-if="isVisible"
    :selector="ModalSelector.BODY"
  >
    <DefaultModal
      :is-alert="!!'1'"
      :width="ModalWidth.SMALL"
      :top-bar-text="'1' ? $t('WEB2_BONUS_REMINDER_MODAL_TITLE_FROM_BONUS_WALLET') : ''"
      is-centered
      :is-full-height="false"
      :is-full-height-centered="false"
      @close="onCloseReminderModal"
    >
      <template #content>
        <div :class="$style['bonus-reminder-modal']">
          <div
            v-if="null"
            :class="$style['bonus-reminder-modal__title']"
          >
            {{ $t('WEB2_BONUS_REMINDER_MODAL_TITLE_FROM_BONUS_WALLET') }}
          </div>
          <div :class="$style['bonus-reminder-modal__details-text']">
            {{ $t('WEB2_BONUS_REMINDER_MODAL_MAX_BET_TEXT', { maxBetLimitFormatted }) }}
          </div>
          <div :class="$style['bonus-reminder-modal__details-text']">
            <TextSlotPlaceholder
              :text="$t('WEB2_BONUS_REMINDER_MODAL_BONUS_RULES')"
            >
              <template #bonuses>
                <span
                  :class="$style['bonus-reminder-modal__details-link']"
                  @click="onBonusLinkClick"
                >
                  {{ $t('WEB2_BONUS_REMINDER_MODAL_BONUS_RULES_LINK') }}
                </span>
              </template>
            </TextSlotPlaceholder>
          </div>
          <VButton
            :kind="ButtonKind.PRIMARY"
            :height="ButtonHeight.LARGE"
            :label="$t('WEB2_BONUS_REMINDER_MODAL_BUTTON_TEXT_CONTINUE')"
            full-width
            :class="$style['bonus-reminder-modal__button']"
            @click="onCloseReminderModal"
          />
          <VCheckbox
            id="bonus-reminder-checkbox"
            :checked="isDontShowAgain"
            :class="$style['bonus-reminder-modal__checkbox']"
            :label="$t('WEB2_BONUS_REMINDER_MODAL_DONT_SHOW_AGAIN')"
            @change="onChangeConsent"
          />
        </div>
      </template>
    </DefaultModal>
  </ModalPortal>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .bonus-reminder-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      @include medium\18\28;

      margin-bottom: 16px;
      color: var(--TextDefault);
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;
      margin-bottom: 16px;
    }

    &__item {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      background-color: if($isDesktop, var(--Layer1), var(--Layer2));
      border-radius: 5px;

      &-title {
        @include regular\14\24\025;

        color: var(--TextPrimary);
        text-align: left;
      }

      &-value {
        @include medium\14\24\025;

        color: var(--TextDefault);
        white-space: nowrap;
      }
    }

    &__button {
      margin: 20px 0;
    }

    &__details {
      &-link {
        @include regular\14\24\025;

        color: var(--TextPrimary);
        text-decoration: underline;
        cursor: pointer;

        @include for-hover {
          &:hover {
            color: var(--BrandHighlight);
          }
        }
      }
    }
  }
}
</style>
