<script lang="ts" setup>
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';

import type { CasinoGameSideBarSwitcherEmits, CasinoGameSideBarSwitcherProps } from './types';

const props = withDefaults(defineProps<CasinoGameSideBarSwitcherProps>(), {

  imageSrc: require('web/src/assets/images/beginner.png'),
});
const emit = defineEmits<CasinoGameSideBarSwitcherEmits>();

const iconName = computed(() => (props.isSideBarOpened ? IconName.EXPAND_RIGHT : IconName.EXPAND_LEFT));
</script>

<template>
  <div v-auto-id="'CasinoGameSideBarSwitcher'"
    :class="{
      [$style['casino-game-sidebar-switcher']]: true,
      [$style['casino-game-sidebar-switcher--opened']]: isSideBarOpened,
    }"
    @click="emit('click')"
  >
    <VIcon
      :name="iconName"
      :size="IconSize.SIZE_16"
      :class="$style['casino-game-sidebar-switcher__icon']"
    />
    <CasinoLoyaltyProgramIcon
      :size="CasinoLoyaltyProgramIconSizes.SIZE_32"
      :image-src="imageSrc"
      :class="$style['casino-game-sidebar-switcher__level-icon']"
    />
  </div>
</template>

<style lang="scss" module>
.casino-game-sidebar-switcher {
  $self: &;

  display: flex;
  align-items: center;
  width: 44px;
  height: 32px;
  background-color: var(--Layer1);
  border-radius: 50px;

  @include for-hover {
    &:hover {
      cursor: pointer;
      background-color: var(--Highlight);
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: -6px;
    margin-left: 2px;
  }

  &__level-icon {
    flex-shrink: 0;
  }

  &--opened {
    flex-direction: row-reverse;

    #{$self}__icon {
      margin-right: 1px;
      margin-left: 0;
    }

    #{$self}__level-icon {
      margin-right: -5px;
    }
  }
}
</style>
