import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { AnyIconName } from '@leon-hub/icons';
import { doMarkAsShownMutation, getLinkedPromotionsByEgsGame } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { EventActionGoogleAnalytics, useGoogleAnalytics } from '@core/analytics';
import { useGraphqlClient } from '@core/app-rest-client';
import { useCurrency, useFormatMoney } from '@core/money';
import { useRouterStore } from '@core/router';

import { ModalSelector } from 'web/src/modules/core/enums';

import type { CasinoGamePromotionsModalProps, LinkedPromotionsByEgsGameDocument } from '../types';

export interface CasinoGamePromotionsModalComposable {
  selector: ModalSelector;
  iconClose: AnyIconName;
  prizePool: ComputedRef<string>;
  casinoModalData: Ref<LinkedPromotionsByEgsGameDocument>;
  isVisible: Ref<boolean>;
  isEnded: Ref<boolean>;
  checkboxIsChecked: Ref<boolean>;
  onCheckboxChange(): void;
  onPlay(): void;
  onHeaderClose(): void;
  onClose(): Promise<void>;
  onReadMore(): void;
  promotionEnd(): void;
}

export default function useCasinoGamePromotionsModal(
  props: CasinoGamePromotionsModalProps,
): CasinoGamePromotionsModalComposable {
  const api = useGraphqlClient();
  const router = useRouter();
  const route = useRoute();
  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();
  const { pushGTM } = useGoogleAnalytics();
  const currentRouteName = toRef(useRouterStore(), 'currentRouteName');

  const selector = ModalSelector.BODY;

  const isEnded = ref(false);
  const casinoModalData = ref();
  const isVisible = ref(false);
  const checkboxIsChecked = ref(false);

  const iconClose = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_CROSS : IconName.CROSS;

  const prizePool = computed(() => formatMoney(
    casinoModalData.value?.jackpot?.amount.value || 0,
    {
      currency: casinoModalData.value?.jackpot?.amount.currency || currency.value,
      reduceAmount: 1_000_000_000,
    },
  ));

  function onCheckboxChange() {
    pushGTM(AnalyticsEvent.ACTION_BUTTON, {
      event: AnalyticsEvent.ACTION_BUTTON,
      event_action: EventActionGoogleAnalytics.TOURNAMENT_CHECK,
      event_label: casinoModalData.value?.promotions[0]?.actionUrl,
      click_text: 'Hide for this game',
      tournament_enroll_type: 'by button',
      time_to_end: casinoModalData.value?.promotions[0]?.endDateNumber,
      game_name: props.activeGame.name,
      game_category: props.activeGame.group.type,
      game_type: props.activeGame.typeId,
      game_provider_id: props.activeGame.providerId,
      game_provider_name: props.activeGame.group.name,
    });

    checkboxIsChecked.value = !checkboxIsChecked.value;
  }

  async function onClose() {
    if (!isEnded.value) {
      await sendMarkAsShown(checkboxIsChecked.value && !!casinoModalData.value?.promotions?.length);
    }
    casinoModalData.value = undefined;
  }

  function onPlay() {
    pushGTM(AnalyticsEvent.CTA_BUTTON, {
      event: AnalyticsEvent.CTA_BUTTON,
      event_action: EventActionGoogleAnalytics.TOURNAMENT_ENROLLMENT,
      event_label: casinoModalData.value?.promotions[0]?.actionUrl,
      click_text: 'Play now',
      tournament_enroll_type: 'by button',
      time_to_end: casinoModalData.value?.promotions[0]?.endDateNumber,
      game_name: props.activeGame.name,
      game_type: props.activeGame.typeId,
      game_provider_id: props.activeGame.providerId,
      game_provider_name: props.activeGame.group.name,
    });

    void onClose();
  }

  function onHeaderClose() {
    pushGTM(AnalyticsEvent.ACTION_BUTTON, {
      event: AnalyticsEvent.ACTION_BUTTON,
      event_action: EventActionGoogleAnalytics.TOURNAMENT_CLOSE_MODAL,
      event_label: casinoModalData.value?.promotions[0]?.actionUrl,
      click_text: 'Close',
      tournament_enroll_type: 'by button',
      time_to_end: casinoModalData.value?.promotions[0]?.endDateNumber,
      game_name: props.activeGame.name,
      game_type: props.activeGame.typeId,
      game_provider_id: props.activeGame.providerId,
      game_provider_name: props.activeGame.group.name,
    });

    void onClose();
  }

  function onReadMore() {
    pushGTM(AnalyticsEvent.ACTION_BUTTON, {
      event: AnalyticsEvent.ACTION_BUTTON,
      event_action: EventActionGoogleAnalytics.TOURNAMENT_INFO_BUTTON_CLICK,
      event_label: casinoModalData.value?.promotions[0]?.actionUrl,
      click_text: 'Read more',
      tournament_enroll_type: 'by button',
      time_to_end: casinoModalData.value?.promotions[0]?.endDateNumber,
      game_name: props.activeGame.name,
      game_type: props.activeGame.typeId,
      game_provider_id: props.activeGame.providerId,
      game_provider_name: props.activeGame.group.name,
    });

    void router.push({
      name: RouteName.CMS_PROMO_TERMS,
      params: {
        cmsKey: casinoModalData.value?.promotions[0].termsOfActions,
      },
    });
  }

  function promotionEnd() {
    isEnded.value = true;
  }

  async function sendMarkAsShown(forever: boolean) {
    await doMarkAsShownMutation(api, (node) => node.mutations.promotions.markAsShown, {
      options: {
        actionUrl: casinoModalData.value.promotions[0].actionUrl,
        providerId: props.activeGame.providerId,
        gameId: props.activeGame.id,
        hideGameForever: forever,
      },
    }, { silent: true });
  }

  async function init() {
    const result = await getLinkedPromotionsByEgsGame(api, (node) => node.queries.promotions.getLinkedPromotionsByEgsGame, {
      options: {
        providerId: props.activeGame.providerId,
        gameId: props.activeGame.id,
      },
    }, { silent: true });

    if (result?.promotions?.length) {
      casinoModalData.value = result;
    }

    if (casinoModalData.value?.jackpot && isVisible.value) {
      pushGTM(AnalyticsEvent.SYSTEM_POPUP, {
        event: AnalyticsEvent.SYSTEM_POPUP,
        event_action: EventActionGoogleAnalytics.TOURNAMENT_POPUP_VIEW,
        event_label: casinoModalData.value?.promotions[0]?.actionUrl,
        tournament_enroll_type: 'by button',
        time_to_end: casinoModalData.value?.promotions[0]?.endDateNumber,
        game_name: props.activeGame.name,
        game_type: props.activeGame.typeId,
        game_provider_id: props.activeGame.providerId,
        game_provider_name: props.activeGame.group.name,
      });
    }
  }

  watch(currentRouteName, (newValue) => {
    isVisible.value = newValue === CasinoRouteName.CASINO_GAME;
  }, { deep: true, immediate: true });

  watch(route, (newValue) => {
    if (newValue.name === CasinoRouteName.CASINO_GAME) {
      void init();
    }
  }, { deep: true, immediate: true });

  return {
    selector,
    iconClose,
    isEnded,
    checkboxIsChecked,
    prizePool,
    casinoModalData,
    isVisible,
    onCheckboxChange,
    onPlay,
    onClose,
    onHeaderClose,
    onReadMore,
    promotionEnd,
  };
}
