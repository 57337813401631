<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import { useCustomerLogin } from '@core/customer';

import CasinoGameErrorView from 'web/src/modules/casino/submodules/game/views/CasinoGameErrorView/CasinoGameErrorView.vue';
import CasinoGameFreespinModal
  from 'web/src/modules/casino/submodules/game/views/CasinoGameFreespinModal/CasinoGameFreespinModal.vue';
import CasinoGamePreview from 'web/src/modules/casino/submodules/game/views/CasinoGamePreview/CasinoGamePreview.vue';
import SCasinoGamePreview from 'web/src/modules/casino/submodules/game/views/CasinoGamePreview/components/SCasinoGamePreview/SCasinoGamePreview.vue';
import CasinoGameStart from 'web/src/modules/casino/submodules/game/views/CasinoGameStart/CasinoGameStart.vue';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';

import {
  useCasinoGameBonus,
  useCasinoGameCashback,
  useCasinoGameRoutePage,
  useCasinoGameRoutePageCordova,
  useCasinoGameSyncState,
} from './composables';

const CasinoGameBonusReminderModal = defineAsyncComponent(
  () => import('web/src/modules/casino/submodules/game/views/CasinoGameBonusReminderModal/CasinoGameBonusReminderModal.vue'),
);

const {
  activeGame,
  isGamePreviewMode,
  metaParameters,
  isStartGameLoading,
  isLoyaltySwitcherEnabled,
  isLoading,
  isDemo,
  gameUrl,
  gameStartCode,
  showBonusReminder,
  isIframeLoaded,
  closeUrl,
  isLoyaltySidebarOpened,
  hideLimitModalOnGamePage,
  maxBetLimit,
  errorData,
  onToggleFavorite,
  onClose,
  onPlay,
  restartGame,
  onIframeLoaded,
  onIframeError,
  onClickSwitch,
  onClickChat,
  onToggleFullscreen,
  onGoToLogin,
  onRealPlay,
  toggleSidebarOpened,
  onErrorClick,
  handleFreespinDialog,
  isReminderModalVisible,
  freespinInfo,
  iframeLoadTimeout,
} = useCasinoGameRoutePage();
const { login } = useCustomerLogin();
const { isCashbackDisplay } = useCasinoGameCashback();
const { cbcBonus } = useCasinoGameBonus(restartGame);

if (null) {
  useCasinoGameRoutePageCordova();
}

if (null) {
  useCasinoGameSyncState({
    activeGame,
    isGamePreviewMode,
  });
}

useSeoMetaParameters(metaParameters);
</script>

<template>
  <div v-auto-id="'CasinoGameRoutePage'" v-if="activeGame">
    <CasinoGameErrorView
      v-if="null && errorData"
      :error="errorData"
      @close="onClose"
      @click="onErrorClick"
    />
    <template v-else-if="null && isGamePreviewMode">
      <CasinoGamePreview
        v-if="!null"
        :active-game="activeGame"
        :error="errorData"
        :is-cashback-display="isCashbackDisplay"
        :is-start-game-loading="isStartGameLoading"
        @toggle-favorite="onToggleFavorite"
        @play="onPlay"
        @close="onClose"
        @restart-game="restartGame"
      />
      <SCasinoGamePreview
        v-else
        :active-game="activeGame"
        :error="errorData"
        :is-cashback-display="isCashbackDisplay"
        :is-start-game-loading="isStartGameLoading"
        @toggle-favorite="onToggleFavorite"
        @play="onPlay"
        @close="onClose"
        @restart-game="restartGame"
      />
    </template>
    <CasinoGameStart
      v-else
      :active-game="activeGame"
      :is-loading="isLoading"
      :is-frame-loaded="isIframeLoaded"
      :start-code-type="gameStartCode"
      :is-demo="isDemo"
      :game-url="gameUrl"
      :error="errorData"
      :user-login="login"
      is-chat-enabled
      :close-href="closeUrl"
      :is-loyalty-switcher-enabled="isLoyaltySwitcherEnabled"
      :is-side-bar-opened="isLoyaltySidebarOpened"
      :is-cashback-display="isCashbackDisplay"
      :timeout="iframeLoadTimeout"
      @set-frame-loaded="onIframeLoaded"
      @click-switch="onClickSwitch"
      @favorite="onToggleFavorite"
      @click-chat="onClickChat"
      @fullscreen="onToggleFullscreen"
      @close="onClose"
      @error-click="onErrorClick"
      @go-to-login="onGoToLogin"
      @go-to-real-game="onRealPlay"
      @side-bar-toggle="toggleSidebarOpened"
      @restart-game="restartGame"
      @on-frame-error="onIframeError"
    />
    <CasinoGameBonusReminderModal
      v-if="showBonusReminder"
      :hide-limit-modal-on-game-page="hideLimitModalOnGamePage"
      :max-bet-limit="maxBetLimit"
      :bonus-balance="cbcBonus"
      @close="handleFreespinDialog"
    />
    <CasinoGameFreespinModal
      v-if="!isLoading && !isReminderModalVisible"
      :freespin-info="freespinInfo"
      :game-id="activeGame.id"
      @restart-game="restartGame"
      @toggle-fullscreen="onToggleFullscreen"
    />
  </div>
</template>
