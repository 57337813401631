<script setup lang="ts">
import {
  ref,
} from 'vue';

import { Timer } from '@leon-hub/utils';
import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import { useI18nLocale } from '@core/i18n';

const { lang } = useI18nLocale();

function getTime(): string {
  try {
    return new Date().toLocaleTimeString(lang.value, { hour: 'numeric', minute: 'numeric' });
  } catch {
    return '';
  }
}

const time = ref(getTime());

let timeout = 0;

onComponentActivated(() => {
  timeout = Timer.setInterval(() => {
    time.value = getTime();
  }, 1000);
});

onComponentDeactivated(() => {
  if (timeout) {
    Timer.clearInterval(timeout);
    timeout = 0;
  }
});
</script>

<template>
  <div v-auto-id="'CasinoGameTime'"
    v-if="time"
    :class="$style['casino-game-time']"
  >
    {{ time }}
  </div>
</template>

<style module lang="scss">
.casino-game-time {
  @include casinoGameTimeFont;

  padding: $casinoGameTimePadding;
  white-space: nowrap;
  background-color: $casinoGameTimeBackgroundColor;
  border-radius: $casinoGameTimeBorderRadius;
}
</style>
