import type {
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { EgsGameType } from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import { useCasinoGameStore } from '../store';

export default class CasinoSlotsGamesPrefetchOld extends AbstractPrefetch {
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const { id } = to.params;
    const gamesStore = useCasinoGameStore();
    const activeGame = toRef(gamesStore, 'activeGame');

    try {
      await gamesStore.loadGameById(String(id));
    } catch {}

    if (!activeGame.value) {
      return {
        name: RouteName.ERROR_NOT_FOUND_404,
      };
    }

    const { url, group, typeId } = activeGame.value;

    const toRouteName = typeId === EgsGameType.LIVE
      ? CasinoRouteName.CASINO_LIVE_GAME
      : CasinoRouteName.CASINO_GAME;

    return router.resolve301location({
      name: toRouteName,
      params: {
        groupUrl: group.url,
        gameUrl: url,
      },
    });
  }
}
