<script lang="ts" setup>
import { ButtonKind, VButton } from '@components/buttons';

import LeaderBoard from 'web/src/modules/promotions/components/LeaderBoard/LeaderBoard.vue';
import LeaderBoardWrapper from 'web/src/modules/promotions/components/LeaderBoardWrapper/LeaderBoardWrapper.vue';
import PromotionDetailsButton from 'web/src/modules/promotions/components/PromotionButton/PromotionButton.vue';
import PromotionDetailsHeader from 'web/src/modules/promotions/components/PromotionDetailsHeader/PromotionDetailsHeader.vue';

import { useCasinoGameLeaderBoard } from './composables';

const {
  board,
  isBlockParticipationButton,
  currentPromotion,
  currentPromoLeaderboardDate,
  promotionButton,
  isDoingActionButton,
  haveLeaderBoard,
  leaderBoardProperties,
  doButtonAction,
  scrollToLeaderBoard,
  goToLoginPage,
  goToDetails,
  goToCustomLink,
  loadMore,
  goToPlay,
} = useCasinoGameLeaderBoard();
</script>

<template>
  <div v-if="currentPromotion">
    <div :class="$style['casino-promotions']">
      <PromotionDetailsHeader
        :class="$style['promotion__title-wrapper']"
        :promotion="currentPromotion"
      />
      <div :class="$style['casino-promotions-button']">
        <PromotionDetailsButton
          v-if="promotionButton"
          :class="{
            [$style['casino-promotions-button__item']]: true,
            [$style['casino-promotions-button__item--primary']]: true,
          }"
          :button-item="promotionButton"
          :is-disabled="isBlockParticipationButton"
          :button-is-loading="isDoingActionButton"
          @participate="doButtonAction"
          @leaderboard="scrollToLeaderBoard"
          @exchange="doButtonAction"
          @login="goToLoginPage"
          @custom="goToCustomLink"
          @play="goToPlay"
        />
        <VButton
          :class="{
            [$style['casino-promotions-button__item']]: true,
            [$style['casino-promotions-button__item--secondary']]: true,
          }"
          :kind="ButtonKind.SECONDARY"
          :label="$t('WEB2_CBC_PROMO_MORE')"
          @click="goToDetails"
        />
      </div>
    </div>
    <div
      v-if="haveLeaderBoard"
      :class="$style['casino-leaderboard']"
    >
      <LeaderBoardWrapper
        ref="board"
        is-game-page
        :subheader="currentPromoLeaderboardDate"
      >
        <LeaderBoard
          v-if="leaderBoardProperties"
          :class="{
            [$style['promotion__leader-board']]: true,
            [$style['promotion__leader-board--default']]: true,
          }"
          v-bind="leaderBoardProperties"
          @load-more="loadMore"
        />
      </LeaderBoardWrapper>
    </div>
  </div>
  <div v-else />
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-promotions {
    padding: 24px 8px 16px;
    margin: if($isDesktop, 8px 0, 8px);
    background: var(--Layer1);
    border-radius: 5px;
  }
}

@include for-layout using($isDesktop) {
  .casino-promotions-button {
    display: flex;
    margin: 24px 0 0;

    @if $isDesktop {
      align-items: center;
      justify-content: center;
    }

    &__item {
      @if $isDesktop {
        width: auto;
        min-width: 160px;
        white-space: nowrap;
      }

      &--primary {
        margin: 0;

        @if not $isDesktop {
          flex: 1 1 auto;
        }
      }

      &--secondary {
        margin-left: 16px;

        @if not $isDesktop {
          flex: 0 1 auto;
        }
      }
    }
  }
}

@include for-layout using ($isDesktop) {
  .casino-leaderboard {
    padding: if($isDesktop, 0, 0 8px);
  }
}
</style>
