<script lang="ts" setup>
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';

import type { CasinoGameFreespinModalEmits, CasinoGameFreespinModalProps } from './types';
import { useCasinoGameFreespinModal } from './composables';

const props = withDefaults(defineProps<CasinoGameFreespinModalProps>(), {
});

const emits = defineEmits<CasinoGameFreespinModalEmits>();

const {
  showCasinoGameFirstModal,
  casinoGameFirstModalProps,
  onClose,
} = useCasinoGameFreespinModal(props, emits);
</script>

<template>
  <ModalPortal v-auto-id="'CasinoGameFreespinModal'"
    :selector="ModalSelector.BODY"
  >
    <ConfirmModal
      v-if="showCasinoGameFirstModal"
      v-bind="casinoGameFirstModalProps"
      @close="onClose"
      @button-click="onClose"
    >
      <template #icon>
        <img
          :src="require('web/src/assets/images/fortune-wheel/firework.svg')"
          :class="$style['casino-game-freespin__firework']"
          alt=""
        >
      </template>
    </ConfirmModal>
  </ModalPortal>
</template>

<style lang="scss" module>
.casino-game-freespin__firework {
  width: 100%;
  max-width: 60px;
}
</style>
